import { CustomSelect } from "../UI/Select";
import { useEffect, useRef, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { getDropdownData } from "./helper";
import "./index.css";

export const Calculator = () => {
  const [carPrices, setCarPrices] = useState([]);
  const [carOptions, setCarsOptions] = useState([]);

  const [auctionPrices, setAuctionPrices] = useState([]);
  const [auctionOptions, setAuctionOptions] = useState([]);

  const [destinationPrices, setDestinationPrices] = useState([]);
  const [destinationOptions, setDestinationOptions] = useState([]);

  const [warehousePrices, setWarehousePrices] = useState([]);
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const [locationPrices, setWLocationPrices] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const [auction, setAuction] = useState();
  const [location, setLocation] = useState();
  const [destination, setDestination] = useState();
  const [carType, setCarType] = useState();
  const [warehouse, setWarehouse] = useState();

  const [price, setPrice] = useState(0);
  const fetchedRef = useRef(false);

  const fetchCarData = async () => {
    if (fetchedRef.current) return;
    fetchedRef.current = true;

    const { options: cOptions, prices: CPrices } = await getDropdownData(
      "car_prices"
    );
    setCarsOptions(cOptions);
    setCarPrices(CPrices);

    const { options: auctOptions, prices: auctPrices } = await getDropdownData(
      "auction_prices"
    );
    setAuctionOptions(auctOptions);
    setAuctionPrices(auctPrices);

    const { options: destOptions, prices: destPrices } = await getDropdownData(
      "destination_prices"
    );
    setDestinationOptions(destOptions);
    setDestinationPrices(destPrices);

    const { options: wareOptions, prices: warePrices } = await getDropdownData(
      "warehouse_prices"
    );
    setWarehouseOptions(wareOptions);
    setWarehousePrices(warePrices);

    const { options: locOptions, prices: locPrices } = await getDropdownData(
      "location_prices"
    );
    setLocationOptions(locOptions);
    setWLocationPrices(locPrices);
  };

  useEffect(() => {
    fetchCarData();
  }, []);

  const calculate = () => {
    const auctionPrice = auctionPrices.find((e) => e.value === auction);
    const locationPrice = locationPrices.find((e) => e.value === location);
    const destinationPrice = destinationPrices.find(
      (e) => e.value === destination
    );
    const warehousePrice = warehousePrices.find((e) => e.value === warehouse);
    const carPrice = carPrices.find((e) => e.value === carType);

    if (auctionPrice || locationPrice || destinationPrice || carPrice) {
      setPrice(
        (auctionPrice?.price ?? 0) +
          (locationPrice?.price ?? 0) +
          (carPrice?.price ?? 0) +
          (warehousePrice?.price ?? 0) +
          (destinationPrice?.price ?? 0)
      );
    }
  };

  return (
    <div className="flex w-full border rounded-2xl p-1 shadow-2xl flex-col lg:flex-row gap-1">
      <div className="bg-img-left w-full lg:w-9/12 p-5 flex flex-col justify-between rounded-xl ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4 sm:gap-5 text-white">
          <CustomSelect
            title={"აუქციონი"}
            options={auctionOptions}
            setter={setAuction}
          />
          <CustomSelect
            title="ავტომობილი"
            options={carOptions}
            setter={setCarType}
          />
          <CustomSelect
            title={"შტატი"}
            options={locationOptions}
            setter={setLocation}
          />
          <CustomSelect
            title={"საწყობი"}
            options={warehouseOptions}
            setter={setWarehouse}
          />
          <CustomSelect
            title={"საბოლოო დანიშნულება"}
            options={destinationOptions}
            setter={setDestination}
          />
        </div>
        <button
          className="mt-6 bg-[#FFFFFF] border border-[#1B5E20] shadow-inner w-full p-3 text-xl rounded-full text-[#2E7D32] font-Arial font-medium hover:-translate-y-1 hover:scale-105"
          onClick={calculate}
        >
          დათვლა
        </button>
      </div>
      <div className="bg-img w-full lg:w-3/12 p-5 rounded-xl text-center text-white">
        <p>ფასი მოცემულია ფოთის პორტამდე</p>
        <span className="text-7xl text-center mt-2 mb-4">{price}$</span>
        <p className="mb-3">დეტალური ინფორმაციის მისაღებად დაგვიკავშირდით</p>
        <Flex gap={5} justifyContent={"center"}>
          <Link
            to="//api.whatsapp.com/send?phone=995555330844"
            style={{
              width: "fit-content",
              borderRadius: "10px",
            }}
          >
            <Flex
              bg={"#25D366"}
              width={"fit-content"}
              py={2}
              px={2}
              gap={3}
              borderRadius={10}
            >
              <FaWhatsapp color="#fff" size={"30"} />
            </Flex>
          </Link>
          <Link
            to="//telegram.me/+995577450906"
            style={{
              width: "fit-content",
              borderRadius: "10px",
            }}
          >
            <Flex
              bg={"#229AD4"}
              width={"fit-content"}
              py={2}
              px={2}
              gap={3}
              borderRadius={10}
              alignItems={"center"}
            >
              <FaTelegram color="#fff" size={"30"} />
            </Flex>
          </Link>
        </Flex>
      </div>
    </div>
  );
};
