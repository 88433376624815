import img from "../../assets/dismantlingHero/13.jpg";
import img_low from "../../assets/dismantlingHero/13_low.jpg";

import { Image } from "@chakra-ui/react";
import CalculateSvg from "../../assets/svg/calculate.svg";
import PropTypes from "prop-types";

export const DismantlingCarHero = ({ scrollToSection }) => {
  return (
    <div className="w-full h-[calc(100vh-64px)] sm:h-[calc(80vh)] relative overflow-hidden">
      <Image
        position="relative"
        objectFit="cover"
        src={img}
        height="100vh"
        className="w-full h-full"
        fallbackSrc={img_low}
      />
      <div className="absolute w-full h-full bg-[#000000b2] top-0 z-10"></div>
      <div className="absolute top-0 flex h-full flex-col text-white z-10 w-full items-center justify-center">
        <div className="w-4/5 text-center">
          <h1 className="text-4xl mb-9 font-Mtavruli">ავტომანქანების დაჭრა</h1>
          <p className="text-lg font-Arial">
            კომპანია "გრინ აუტო იმპორტი" გთავაზობთ ავტომობილების დაჭრის სერვისს
            ამერიკის შეერთებულ შტატებში, კერძოდ ნიუჯერსიში. სერვისი მოიცავს
            კონკრეტული ლოკაციიდან ავტომანქანის საწყობამდე გადაზიდვას,
            დაჭრა-დანაწევრების პროცესისთვის მომზადებას და შემდგომ მომხმარებლის
            მოთხოვნის შესაბამისად მინ ნაწილებლად დაჭრას და კონტეინერში
            მოთავსებას.
          </p>
          <button
            onClick={scrollToSection}
            className="flex bg-white mx-auto mt-5 text-black rounded-lg items-center px-2 py-1 border border-black"
          >
            <Image src={CalculateSvg} className="w-8 h-8" loading="lazy" />
            კალკულატორი
          </button>
        </div>
      </div>
    </div>
  );
};

DismantlingCarHero.propTypes = {
  scrollToSection: PropTypes.func,
};
