import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase";

export const getDropdownData = async (collectionName) => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, `calculator/data/${collectionName}`))
    );
    const carList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const options = carList.map((element) => ({
      value: element.name,
      label: element.label,
    }));
    const prices = carList.map((element) => ({
      value: element.name,
      price: element.price,
    }));

    return { options, prices };
  } catch (error) {
    console.error("Error fetching resumes:", error);
    return { options: [], prices: [] };
  }
};
