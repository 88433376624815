import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import FormItem from "./formItem";

const ItemEditor = ({ item, onSubmit, isSaving }) => {
  const [updatedItem, setUpdatedItem] = useState(item);

  const handleInputChange = (e, field) => {
    let { value } = e.target;
    if (e.target.type === "number") {
      value = parseFloat(value);
    }
    console.log(typeof value);
    setUpdatedItem((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const checkIfItemUpdated = () => {
    return JSON.stringify(item) !== JSON.stringify(updatedItem);
  };

  return (
    <form className="flex flex-col gap-3 mb-4 pb-3 border-b-2 border-black">
      <div className="flex gap-4">
        <FormItem
          title="name"
          type={"text"}
          value={updatedItem.name}
          onChange={(e) => handleInputChange(e, "name")}
        />
        <FormItem
          title="label"
          type={"text"}
          value={updatedItem.label}
          onChange={(e) => handleInputChange(e, "label")}
        />
        <FormItem
          title="price"
          type={"number"}
          value={updatedItem.price}
          onChange={(e) => handleInputChange(e, "price")}
        />
      </div>
      <Button
        variant={"solid"}
        color={"white"}
        backgroundColor={"green.400"}
        type="button"
        onClick={() => {
          if (!isSaving) {
            onSubmit(updatedItem);
          }
        }}
        disabled={!checkIfItemUpdated()}
      >
        {isSaving ? "Saving..." : "Update"}
      </Button>
    </form>
  );
};

export default ItemEditor;
