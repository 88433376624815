import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../../../firebase";
import Dialog from "../../../../../components/Modal";

export default function Messages() {
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMessage, setIsSelectedMessage] = useState();

  const fetchMessages = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "contacts"), orderBy("timestamp", "desc"))
      );
      const messagesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesList);
    } catch (error) {
      console.error("Error fetching resumes:", error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="grid gap-3 md:grid-cols-3 lg:grid-cols-4">
      {messages.length > 0 ? (
        <>
          {messages.map((item) => {
            return (
              <div
                key={item.id}
                className="relative flex h-40 flex-1 flex-col overflow-hidden border border-solid border-[#bbbbbb] p-3 font-spartan shadow-xl hover:shadow-2xl"
                onClick={() => {
                  setIsOpen(true);
                  setIsSelectedMessage(item.id);
                }}
              >
                <span className="absolute right-2 bottom-2 text-sm z-20">
                  {item.timestamp.toDate().toLocaleString()}
                </span>
                <h3 className="text-xl font-bold">
                  {item.name} {item.lastName}
                </h3>
                <span className="text-lg font-semibold">
                  {item.email_or_num}
                </span>
                <div className="relative z-10 break-words text-lg font-semibold flex flex-col gap-2">
                  <p>{item.message}</p>
                  <p>{item.number}</p>
                </div>
                <div className="absolute bottom-0 left-0 z-10 h-1/2 w-full bg-gradient-to-t from-[#ffffff] to-transparent"></div>
              </div>
            );
          })}
          <Dialog isOpen={isOpen} onClose={onClose} title="Message">
            {messages
              .filter((item) => item.id === selectedMessage)
              .map((i) => {
                return (
                  <div key={i.id} className="flex flex-col gap-3 font-spartan">
                    <div>
                      <span className="font-bold">NAME: </span>
                      {i.name} {i.lastName}
                    </div>
                    <div>
                      <span className="font-bold">NUMBER: </span>
                      {i.number}
                    </div>
                    <div className="break-words">
                      <span className="font-bold">Message: </span>
                      {i.message}
                    </div>
                  </div>
                );
              })}
          </Dialog>
        </>
      ) : (
        <div className="mt-3 text-2xl">No messages to display</div>
      )}
    </div>
  );
}
