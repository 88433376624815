import React from "react";
import Messages from "./messages";
import Calculator from "./calculator";

export default function Content({ query }) {
  const getContent = () => {
    if (query === "home") {
      return <div>Home</div>;
    } else if (query === "messages") {
      return <Messages />;
    } else if (query === "calculator") {
      return <Calculator />;
    }
    return <div>{query} not found</div>;
  };

  return getContent();
}
