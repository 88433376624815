import { Link, useSearchParams } from "react-router-dom";
import { useAuth } from "../../auth/AuthProvider";
import Content from "./components/content";

export const ADMIN_NAV_ITEMS = [
  {
    title: "home",
    href: "home",
  },
  {
    title: "messages",
    href: "messages",
  },
  {
    title: "calculator",
    href: "calculator",
  },
];

const Dashboard = () => {
  const { user, login, logout } = useAuth();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("selectedPage") || "home";

  return user ? (
    <div className="flex h-screen bg-gray-100">
      <div className="flex w-64 flex-col bg-gray-800">
        <div className="flex h-16 items-center justify-center gap-2 bg-gray-900">
          <p className="animate-text-gradient inline-flex bg-gradient-to-r from-[#fafafa] via-[#d4d4d4] to-[#a3a3a3] bg-[200%_auto] bg-clip-text font-spartan text-2xl text-transparent">
            Green Auto Import
          </p>
        </div>
        <div className="flex flex-1 flex-col justify-between overflow-y-auto">
          <nav className="bg-gray-800 px-2 py-4">
            {ADMIN_NAV_ITEMS.map((item) => {
              return (
                <Link
                  key={item.title}
                  to={`?selectedPage=${item.href}`}
                  className={`flex w-full items-center px-4 py-2 text-gray-100 hover:bg-gray-700 ${
                    query === item.href ? "bg-gray-600" : "bg-transparent"
                  }`}
                >
                  <span className="mt-1 capitalize">{item.title}</span>
                </Link>
              );
            })}
          </nav>
          <div className="px-2 py-4 text-center font-medium">
            <button
              className="p-2 text-xl text-red-600 underline hover:text-red-500"
              onClick={() => logout()}
            >
              Log out
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="flex h-16 items-center justify-between border-b border-gray-200 bg-white">
          {/* FEAT:profile image */}
        </div>
        <div className="p-4">
          <div className="text-black">
            <p className="mt-2 font-spartan text-4xl uppercase text-gray-600">
              {query}
            </p>
            <Content query={query} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-2xl mb-4">Please Log In</h1>
      <button
        onClick={login}
        className="px-4 py-2 bg-blue-500 text-white rounded-lg"
      >
        Sign in with Google
      </button>
    </div>
  );
};

export default Dashboard;
