import React, { useEffect, useState } from "react";
import Logo from "../../assets/greenautoicon.jpg";
// import "./style.css";
import { Image } from "@chakra-ui/react";

export default function Loading() {
  const [stillLoading, setStillLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress < 95) {
      const timer = setTimeout(() => {
        setProgress(progress + 1);
      }, 3);
      return () => clearTimeout(timer);
    }
  }, [progress]);

  useEffect(() => {
    if (stillLoading) {
      document.body.style.overflow = "hidden";
    }

    const timerProgress = setTimeout(() => {
      setProgress(100);
    }, 1800);

    const timer = setTimeout(() => {
      setProgress(100);
      setStillLoading(false);
      document.body.style.overflow = "unset";
    }, 2000);

    return () => {
      clearTimeout(timer);
      clearTimeout(timerProgress);
      document.body.style.overflow = "unset";
    };
  }, [stillLoading]);

  return stillLoading ? (
    <div className="fixed right-0 top-0 z-[10050] flex flex-col h-screen w-full items-center justify-center bg-white">
      <Image
        src={Logo}
        alt="logo"
        className="logo-rotate h-28 w-28"
        loading="lazy"
      />
      <div className="w-72 max-w-md bg-gray-300 rounded-full overflow-hidden">
        <div
          className="h-1 bg-green-500 text-white text-sm font-bold flex items-center justify-center transition-all duration-75"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  ) : null;
}
