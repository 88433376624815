import React, { useState } from "react";
import DataEditor from "../../dataEditor";
import { AccordionItem } from "../../../../../components/Accordion/accordionItem";
import Add from "../../../../../assets/svg/add.svg";
import Remove from "../../../../../assets/svg/min.svg";
import { db } from "../../../../../firebase";
import { collection, getDocs } from "firebase/firestore";

export default function Calculator() {
  const canExpandMultipleRows = true;
  const [isOpen, setIsOpen] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({});
  const collectionNames = [
    "auction_prices",
    "car_prices",
    "destination_prices",
    "warehouse_prices",
    "location_prices",
  ];

  const fetchItems = async (collectionName) => {
    if (data[collectionName]) return;

    setLoading((prev) => ({ ...prev, [collectionName]: true }));

    try {
      const querySnapshot = await getDocs(
        collection(db, `calculator/data/${collectionName}`)
      );
      const fetchedItems = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData((prev) => ({ ...prev, [collectionName]: fetchedItems }));
    } catch (error) {
      console.error("Error fetching items:", error);
    } finally {
      setLoading((prev) => ({ ...prev, [collectionName]: false }));
    }
  };

  const handleToggle = (collectionName) => {
    if (canExpandMultipleRows) {
      setIsOpen((prev) => ({
        ...prev,
        [collectionName]: !prev[collectionName],
      }));
    } else {
      setIsOpen({ [collectionName]: !isOpen[collectionName] });
    }

    if (!isOpen[collectionName]) {
      fetchItems(collectionName);
    }
  };

  return (
    <div>
      {collectionNames.map((item) => (
        <AccordionItem
          key={item}
          isOpen={isOpen[item]}
          onClick={() => handleToggle(item)}
          label={item.split("_").join(" ")}
          content={
            loading[item] ? (
              <div>Loading...</div>
            ) : (
              <DataEditor
                collectionName={item}
                initialData={data[item] || []}
              />
            )
          }
          icon={Add}
          closeIcon={Remove}
        />
      ))}
    </div>
  );
}
