import React from "react";
import { cn } from "../../utils/cn";

export const AccordionItem = ({
  isOpen,
  onClick,
  label,
  content,
  icon,
  closeIcon,
}) => {
  return (
    <div
      className={cn(
        "w-full py-5 px-4 border-b-black border-b last:border-b-0 border-opacity-25",
        {
          "pb-0": isOpen,
        }
      )}
    >
      <div
        className="flex w-full items-center justify-between"
        onClick={onClick}
      >
        <span className="text-xl uppercase text-black font-Mtavruli">
          {label}
        </span>
        <img
          src={isOpen ? closeIcon : icon}
          alt="arrow icon"
          className={cn("rotate-180 transition-all ", {
            "rotate-0 transform": isOpen,
          })}
        />
      </div>
      <div
        className={cn("overflow-hidden text-base text-wrap", {
          "m-0 h-0": !isOpen,
          "my-3 h-full": isOpen,
        })}
      >
        {content}
      </div>
    </div>
  );
};
