import { Input } from "@chakra-ui/react";
import React from "react";

export default function FormItem({ title, value, onChange, type }) {
  return (
    <label className="flex flex-col w-full">
      {title}:
      <Input
        className="!border-gray-500"
        type={type}
        value={value}
        onChange={onChange}
      />
    </label>
  );
}
