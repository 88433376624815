import { useCallback } from "react";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { IoChevronDownSharp } from "react-icons/io5";
import US from "country-flag-icons/react/3x2/US";
import GE from "country-flag-icons/react/3x2/GE";
import { useTranslation } from "react-i18next";

const Flags = ({ ...props }) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = useCallback(
    ({ currentTarget }) => {
      if (
        currentTarget.lastElementChild.innerHTML === "ქართული" &&
        i18n.language !== "geo"
      ) {
        i18n.changeLanguage("geo");
        localStorage.setItem("lng", "geo");
      }
      if (
        currentTarget.lastElementChild.innerHTML === "English" &&
        i18n.language !== "en"
      ) {
        i18n.changeLanguage("en");
        localStorage.setItem("lng", "en");
      } else return;
    },
    [i18n]
  );

  const flags = [
    {
      language: "English",
      component: <US style={{ width: "32px", height: "32px" }} />,
      id: "en",
    },
    {
      language: "ქართული",
      component: <GE style={{ width: "32px", height: "32px" }} />,
      id: "geo",
    },
  ];

  return (
    <Flex
      position="fixed"
      top={3}
      bottom="auto"
      zIndex="10000"
      right={10}
      alignItems="center"
      textAlign="center"
      width="fit-content"
      borderRadius={10}
      justifyContent="right"
      backgroundColor="white"
      {...props}
    >
      <Menu matchWidth="true">
        <MenuButton
          as={Button}
          borderRadius="md"
          px={4}
          py={2}
          transition="all 0.2s"
          _hover={{ bg: "gray.200" }}
          rightIcon={<IoChevronDownSharp />}
        >
          {t("Select language")}
        </MenuButton>
        <MenuList>
          {flags.map((item) => (
            <MenuItem
              icon={item.component}
              onClick={handleLanguageChange}
              key={item.id}
              id={item.id}
            >
              {item.language}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default Flags;
