import React, { useState } from "react";
import { db } from "../../../../firebase";
import { updateDoc, doc } from "firebase/firestore";
import ItemEditor from "../itemEditor";
import { toast } from "react-toastify";
import { Input, Button } from "@chakra-ui/react";

const DataEditor = ({ collectionName, initialData }) => {
  const [items, setItems] = useState(initialData);
  const [foundItems, setFoundItems] = useState([]);
  const [search, setSearch] = useState("");
  const [adjustAmount, setAdjustAmount] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = async (updatedItem) => {
    setIsSaving(true);
    const itemRef = doc(
      db,
      `calculator/data/${collectionName}`,
      updatedItem.id
    );

    try {
      await updateDoc(itemRef, updatedItem);
      setItems((prevState) =>
        prevState.map((item) =>
          item.id === updatedItem.id ? { ...item, ...updatedItem } : item
        )
      );
      toast.success("Item updated successfully!");
    } catch (error) {
      console.error("Error updating item:", error);
      toast.error("Failed to update item.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleSearchChange = (value) => {
    setFoundItems(
      items.filter((item) =>
        item.name.toLowerCase().startsWith(value.toLowerCase())
      )
    );
  };

  const handleAdjustPrices = async (type) => {
    const amount = parseFloat(adjustAmount);
    if (isNaN(amount) || amount <= 0) {
      return toast.error("Please enter a valid number greater than 0!");
    }

    const adjustment = type === "increase" ? amount : -amount;

    setIsSaving(true);

    try {
      const updatedItems = items.map((item) => ({
        ...item,
        price: (item.price || 0) + adjustment,
      }));

      await Promise.all(
        updatedItems.map((item) =>
          updateDoc(doc(db, `calculator/data/${collectionName}`, item.id), {
            price: item.price,
          })
        )
      );

      setItems(updatedItems);
      toast.success(
        `Prices ${
          type === "increase" ? "increased" : "decreased"
        } by ${amount} for "${collectionName}"!`
      );
    } catch (error) {
      console.error("Error updating prices:", error);
      toast.error("Failed to update prices.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="m-1">
      <Input
        onChange={(e) => {
          setSearch(e.target.value);
          handleSearchChange(e.target.value);
        }}
        value={search}
        placeholder="Search items..."
        className="mb-3 !border-gray-500"
      />

      <div className="mb-8">
        <p className="text-red-500 font-600 mb-1">
          ამის ცვლილებით ყველას ფასი შეიცვლება {collectionName}'ში
        </p>
        <Input
          type="number"
          placeholder="Enter amount"
          value={adjustAmount}
          onChange={(e) => setAdjustAmount(e.target.value)}
          className="!border-gray-500 mb-1"
        />
        <div className="flex gap-10">
          <Button
            onClick={() => handleAdjustPrices("increase")}
            colorScheme="green"
            isLoading={isSaving}
            className="w-full"
          >
            + Increase Price
          </Button>
          <Button
            onClick={() => handleAdjustPrices("decrease")}
            colorScheme="red"
            isLoading={isSaving}
            className="w-full"
          >
            - Decrease Price
          </Button>
        </div>
      </div>

      {(search.length > 0 ? foundItems : items).map((item) => (
        <ItemEditor
          isSaving={isSaving}
          key={item.id}
          item={item}
          onSubmit={handleUpdate}
        />
      ))}
    </div>
  );
};

export default DataEditor;
