import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, provider, signInWithPopup, signOut, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const fetchAllowedUsers = async () => {
    const docRef = doc(db, "config", "allowedUsers");
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data().emails : [];
  };

  const login = async () => {
    const allowedEmails = await fetchAllowedUsers();

    try {
      const result = await signInWithPopup(auth, provider);

      if (!allowedEmails.includes(result.user.email)) {
        alert("Unauthorized user");
        await signOut(auth);
        return;
      }

      setUser(result.user);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const logout = async () => {
    await signOut(auth);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
